import React from "react";
import "./social.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAt, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { faLinkedin, faSalesforce } from "@fortawesome/free-brands-svg-icons";

import resumePdf from "../../documents/files/Cecil_Cheng_Resume.pdf";

function Social() {
  return (
    <div className="social-container">
      <ul>
        <li className="email">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="mailto:cecilyhcheng@gmail.com"
          >
            <FontAwesomeIcon icon={faAt} />
          </a>
        </li>
        <li className="linkedin">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.linkedin.com/in/cecilcheng/"
          >
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
        </li>
        <li className="pdf">
          <a target="_blank" rel="noopener noreferrer" href={resumePdf}>
            <FontAwesomeIcon icon={faFilePdf} />
          </a>
        </li>
        <li className="salesforce">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://trailblazer.me/id/cecilcheng"
          >
            <FontAwesomeIcon icon={faSalesforce} />
          </a>
        </li>
      </ul>
    </div>
  );
}

export default Social;
