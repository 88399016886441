import React from "react";

import "./App.css";

import Photo from "./components/photo/Photo";
import Education from "./components/education/Education";
import Social from "./components/social/Social";
import AboutMe from "./components/about-me/AboutMe";
import CoreSkillset from "./components/skillset/core-skillset/CoreSkillset";
import SupplementarySkillset from "./components/skillset/supplementary-skillset/SupplementarySkillset";
import Work from "./components/work/WorkList/WorkList";

/* todo
double check www redirect 
install SSL certificate
make it so that there is less white space on the left of the left column
*/

function Left() {
  return (
    <div className="left">
      <Photo></Photo>
      <Education></Education>
      <CoreSkillset></CoreSkillset>
      <SupplementarySkillset></SupplementarySkillset>
      <Social></Social>
    </div>
  );
}

function Right() {
  return (
    <div className="right">
      <AboutMe></AboutMe>
      <Work></Work>
    </div>
  );
}

function App() {
  return (
    <div className="main-container">
      <div className="flex-row">
        <Left></Left>
        <Right></Right>
      </div>
    </div>
  );
}

export default App;
