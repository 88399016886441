import React from "react";
import "./about-me.css";

function AboutMe() {
  return (
    <div className="about-me-container">
      <h3>About me</h3>
      <div className="text-regular">
        Cecil Cheng is a highly driven and adept instructional design consultant
        who uses research and data to design effective learning interventions
        and training products. She has the unique ability to ask revealing
        questions to accurately assess problems and discover learning solutions
        both at billion-dollar corporations like Edwards Lifesciences and
        Pretium Partners, as well as world class universities such as USC and
        UCLA. She is fearless in challenging workplace heuristics or "the old
        way of doing things" and approaches learning design with integrity and
        precision, employing methods based on scholarly research. Cecil
        currently serves as a Senior Instructional Designer at UCLA, the number
        one ranking public university in the nation.
      </div>
      <p>
        Check out her upcoming ATD Talk on{" "}
        <a
          href="https://atdla.org/event-4100065"
          target="_blank"
          rel="noopener noreferrer"
        >
          How to Measure Learning Success in the Workplace — Building Effective
          Assessments
        </a>
      </p>
    </div>
  );
}

export default AboutMe;
