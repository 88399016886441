import React from "react";
import "./photo.css";
import profilePic from "./photo.png";

function Photo() {
  return (
    <div className="photo-container">
      <div className="avatar">
        <div className="wrap">
          <img src={profilePic} alt="Cecil Cheng"></img>
        </div>
      </div>
      <h1 className="text-bold">Cecil Cheng</h1>
      <h2 className="text-bold">Senior Instructional Designer at UCLA​​</h2>
      <p className="text-italic">Los Angeles</p>
    </div>
  );
}

export default Photo;
