import React from "react";
import "./work-list.css";

import WorkItem from "../WorkItem/WorkItem";

import salesforce from "../../../documents/images/salesforce.png";
import techAI from "../../../documents/images/tech-ai.png";
import mixInfographic from "../../../documents/images/mix_infographic.png";

function WorkList() {
  return (
    <div className="work-list-container">
      <h3 className="text-regular">Portfolio samples</h3>
      <div className="work-list-block">
        <div className="projects-row">
          <div className="projectContainer">
            <h4>My Resume Video</h4>
            <div className="iframeContainer">
              <iframe
                title="resume video"
                src="https://www.youtube.com/embed/dzzoABCvMSM"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <WorkItem project={salesforce}>
            <h4>ADDIE Model and Salesforce Training</h4>
            <p>
              This model is a systematic approach to the Analysis, Design,
              Development, Implementation, and Evaluation of learning programs
              and materials and is a foundational cornerstone of Instructional
              Design.
            </p>
            <a
              href="http://addie.cecilcheng.com/story_html5.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Interactive ADDIE Model
            </a>
          </WorkItem>
          <div className="projectContainer">
            <h4>ADDIE Training Program</h4>
            <p>
              This is a short, animated video that I created using Powtoon to
              introduce new learners to the ADDIE Model.
            </p>
            <div className="iframeContainer">
              <iframe
                title="resume video"
                src="https://www.youtube.com/embed/EMpVpAQpNEw"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <WorkItem project={techAI}>
            <h4>Tech AI New Hire Onboarding</h4>
            <p>
              This is a short interactive onboarding experience that I created
              using Articulate Storyline.
            </p>
            <a
              href="http://tech-ai.cecilcheng.com/story.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Interactive Tech AI Onboarding
            </a>
          </WorkItem>
          <WorkItem project={mixInfographic}>
            <h4>Organizational Infographic</h4>
            <p>
              This infographic was created to help identify the club's purpose
              and member demographics to potential investors and affilitates.
            </p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://create.piktochart.com/output/34064107-mix-infographic-v1-copy"
            >
              See More
            </a>
          </WorkItem>
          <div className="projectContainer">
            <h4>Medicare Reimbursement Training</h4>
            <p>
              This infographic slide show summarizes and contextualizes an
              additional insurance payment offered by the Centers of Medicare
              and Medicaid (CMS) for severe, inpatient hospital procedures that
              involve the use of new medical technology. This presentation also
              serves as a Worked Example (Job Aid) for calculating the amount of
              additional insurance payment that CMS will pay on a case-by-case
              basis.
            </p>
            <div className="iframeContainer">
              <iframe
                title="NTAP for Surgical Aortic Valve System"
                src="https://docs.google.com/presentation/d/e/2PACX-1vTcfcIzip50gFvnmcHpfM-DtZUrCuZUi6LARJ36Hyu7E22GfY1dbb3rIGpZ3_lTswf4VAjQJAx3BdpP/embed?start=true&loop=true&delayms=3000"
                frameBorder="0"
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <div className="projectContainer">
            <h4>Airway Management Flowchart</h4>
            <p>
              Please click on hand icon below to pan diagram. Below is the
              blueprint for a learning game to help First Year Oral Surgery
              Residents learn how to correctly respond to a Patient who goes
              apneic (stops breathing) under deep sedation during an oral
              surgery procedure (e.g. teeth extraction).
            </p>
            <div className="iframeContainer">
              <iframe
                title="Surgery Progress Chart"
                src="https://miro.com/app/embed/o9J_k042NBs=/?"
                frameBorder="0"
                scrolling="no"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WorkList;
