import React from 'react';
import './modal.css';

const Modal = (props) => {
    const onClose = () => {
        props.onClose && props.onClose();
    }

    if(!props.show) {
        return null;
    }
    return (
        <div className="modal-container" onClick={onClose}>
            <div className="modal-content">
                <img src={props.project} alt="project"></img>
            </div>
            <div className="modal-actions">
                <button className="text-regular">Close</button>
            </div>
        </div>
    )
  }

export default Modal;
