import React from "react";
import "./core-skillset.css";

function CoreSkillset() {
  return (
    <div className="core-skillset-container">
      <p className="text-italic">Core Skillset</p>
      <ul>
        <li className="text-light">
          Articulate 360 Storyline, Rise, Review, Replay, Peek
        </li>
        <li className="text-light">
          Adobe Creative Cloud Photoshop, Illustrator, Audition, Spark
        </li>
        <li className="text-light">TechSmith Snagit, Camtasia</li>
        <li className="text-light">Powtoon, Biteable</li>
      </ul>
    </div>
  );
}

export default CoreSkillset;
