import React, { useState, useEffect } from "react";

import Modal from "../../modal/Modal";
import "./work-item.css";

const WorkItem = (props) => {
  const [show, setShow] = useState(false);

  const toggleModal = () => {
    setShow((show) => !show);
  };

  useEffect(() => {
    if (show) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [show]);

  return (
    <div>
      <div className="work-item-container">
        <div className="work-item-children">{props.children}</div>
        <div className="work-item-content" onClick={() => toggleModal()}>
          {props.project && (
            <div
              className="thumbnail"
              style={{ backgroundImage: `url(${props.project}` }}
            ></div>
          )}
        </div>
      </div>
      <Modal show={show} project={props.project} onClose={toggleModal}></Modal>
    </div>
  );
};

export default WorkItem;
