import React from "react";
import "./supplementary-skillset.css";

function SupplementarySkillset() {
  return (
    <div className="supplementary-skillset-container">
      <p className="text-italic">Supplementary Skillset</p>
      <ul>
        <li className="text-light">
          Canvas Learning Management System (LMS), Absorb LMS
        </li>
        <li className="text-light">
          Salesforce, Definitive HC, Kuali Financial System
        </li>
        <li className="text-light">Microsoft Office Suite, Google Suite</li>
      </ul>
    </div>
  );
}

export default SupplementarySkillset;
