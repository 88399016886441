import React from "react";
import "./education.css";

function Education() {
  return (
    <div className="education-container">
      <p className="text-italic">Education</p>
      <ul className="education-university">
        <li className="text-regular">
          University of Southern California
          <span className="text-italic"> - Magna Cum Laude</span>
        </li>
        <li className="text-light">
          Master of Education in Learning Design & Technology
        </li>
      </ul>
    </div>
  );
}

export default Education;
